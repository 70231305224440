<template>
  <v-dialog v-model="dialog" scrollable max-width="960px">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot></slot>
      </span>
    </template>
    <v-card>
         <v-card-title class="text-h6 primary white--text mb-6">
         Budget-Transfers in Snapshot {{ snapshot }}
      </v-card-title>
      <v-card-text>
        <ul class="transfers">
        <li v-for="(item, index) in transfers" :key="index">
            <span class="nowrap"> {{ d2s(item.transfer_date, 'dateshort') }} </span>
            <span class="nowrap"> <strong>{{item.from.name }}</strong> <i class="mdi mdi-transfer-right"></i> <strong>{{ item.to.name }}</strong> </span>
            <span> {{ item.client.name }} </span>
            <span class="nowrap"> {{ numerus('? Kampagne|n', item.from.projects.length) }}</span>
            <span class="right bold"> {{ toLocale(item.sum) }}</span>
        </li>
        </ul>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">
              {{ trl("close") }}
            </v-btn>
        </v-card-actions>
</v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from "luxon";
import { trl, getDivisionName, getClientName, getAttributeName, numerus } from "@/utils/strings";
import { toLocale, coloredToLocale } from "@/utils/numbers";
import { d2s } from "@/utils/dates";

export default {
    data(){
        return {
            title: "Transfers",
            dialog: false,
            current: []
        }

    },
    props: ["transfers", "field", "snapshot"],
    computed: {
        // acc(){
            
        //     let data = {};
        //     this.current.forEach(row => {

        //         if (!data[row.parent.division + '-' + row.division_id + '-' + row.client_id]) {
        //             data[row.parent.division + '-' + row.division_id + '-' + row.client_id] = [];
        //         }

        //         data[row.parent.division + '-' + row.division_id + '-' + row.client_id].push({
        //             from: row.parent.division,
        //             to: row.division_id,
        //             client: row.client_id,
        //             budget: row[this.field],
        //             created_at: row.parent.deleted_at
        //         })
        //     });

        //     let acc = [];
        //     for (let x of Object.keys(data)) {
        //         let split = x.split("-");
        //         acc.push({
        //             from: getDivisionName(Number(split[0])),
        //             to: getDivisionName(Number(split[1])),
        //             client: getClientName(Number(split[2])),
        //             projects: data[x],
        //             sum: data[x].reduce((accumulator, current) => accumulator + current.budget, 0),
        //             created_at: data[x][0].created_at
        //         });
        //     }

        //     return acc.sort((a, b) => a.created_at < b.created_at ? -1 : a.created_at > b.created_at ? 1 : 0)
        // }
    },
    methods: {
        trl,
        d2s,
        toLocale,
        numerus
    },
    watch: {
        dialog: function (val) {
            if (!val) return;
            this.current = this.transfers.filter(item => item.snapshot === this.snapshot);
        }
    }
}
</script>

<style lang="scss" scoped>
.transfers{
    
    font-size:16px;
    display: table;
    margin:0;
    padding:0;

    li{
        display: block;
        display: table-row;

        & > span {
            display:table-cell;
            padding: .25em 1.5em .25em 0;

            &.right{
                text-align:right;
                
                padding-left:1.5em;
                padding-right:0em;
            }

            &.bold{
                font-weight: bold;
            }

            &.nowrap{
                white-space: nowrap;
            }
        }
    }
}

</style>