<template>
  <v-container fluid class="px-15">
    <v-row class="my-3 px-3">
    <h1>
      {{ this.forecast.title }}
      <small>– Entwicklung {{ trl("budget_" + field) }}</small>
    </h1>
    <v-spacer></v-spacer>
    <ForecastTools class="mr-4 mt-2"></ForecastTools>
    </v-row>
    <v-progress-linear
      v-if="loading"
      indeterminate
      rounded
      color="primary"
    ></v-progress-linear>
    <div v-else>

    <v-row class="pt-6">
      <v-col cols="2">
        <v-select
          v-model="key"
          :items="keys"
          item-value="value"
          item-text="label"
          label="kumuliert auf"
          outlined
          dense
          @change="changeKey"
        ></v-select>
      </v-col>

      <v-col cols="2">
        <v-select
          v-model="field"
          :items="$store.state.budget.fields.filter((f) => f.sumup)"
          item-value="key"
          item-text="label"
          label="betrachtetes Budget"
          outlined
          dense
          @change="setOutput"
          
        ></v-select>
      </v-col>
      
      <v-col cols="3">
        <StatusSelector @change="statuschange" />
      </v-col>
<v-col cols="2">
        <v-text-field 
        v-model="search" 
        prepend-inner-icon="mdi-magnify"
        clearable
        outlined 
        dense 
        :label="`${keys.find(k => k.value === key).label} filtern`"
        ></v-text-field>

      </v-col>
        <v-col cols="3">
          <v-btn-toggle 
          rounded
          
          dense
          mandatory
          color="primary"
           v-model="displayMode"
           >
            <v-btn class="ma-0" color="white"><v-icon small>mdi-sigma</v-icon></v-btn>
            <v-btn class="ma-0" color="white"><v-icon small>mdi-delta</v-icon></v-btn>
            <v-btn class="ma-0" color="white"><v-icon small>mdi-percent</v-icon></v-btn>
          </v-btn-toggle>
          <v-btn icon class="ml-6" color="primary" @click="download"
            ><v-icon small>mdi-tray-arrow-down</v-icon></v-btn
          >
        </v-col>

    </v-row>

    <v-row>
      <v-simple-table dense class="elevation-2 mb-6" style="width:100%">
            <thead>
                <tr>
                    <th class="primary white--text text-left">Budget-Status</th>
                    <th class="primary white--text text-right" v-for="col in columns" :key="col">{{ col }}</th>
                    <th class="primary white--text text-right">Diff.*</th>
                    <th class="primary white--text text-right">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <v-icon size="14" :color="$store.state.budget.status[2].color">{{
                $store.state.budget.status[2].icon
              }}</v-icon>
                <span>{{ $store.state.budget.status[2].label }}</span>
                </td>
                <td 
                        class="text-right"
                        v-for="(col, index) in columns" 
                        :key="index"
                        v-html="totals['status_' + $store.state.budget.status[2].value][index+1]"
                    >
                    </td>
                    <td class="text-right"
                     v-html="totals['status_' + $store.state.budget.status[2].value].at(-1)"
                    ></td><td class="text-right"></td>
              </tr>

              <tr>
                <td>
                  <v-icon size="14" :color="$store.state.budget.status[1].color">{{
                $store.state.budget.status[1].icon
              }}</v-icon>
                <span>{{ $store.state.budget.status[1].label }}</span>
                </td>
                <td 
                        class="text-right"
                        v-for="(col, index) in columns" 
                        :key="index"
                        v-html="totals['status_' + $store.state.budget.status[1].value][index+1]"
                    >
                    </td>
                    <td class="text-right"
                    v-html="totals['status_' + $store.state.budget.status[1].value].at(-1)"
                    ></td><td class="text-right"></td>

              </tr>

              <tr class="totals">
                <td>
                   <v-icon size="14" :color="$store.state.budget.status[2].color">{{
                    $store.state.budget.status[2].icon
                  }}</v-icon>
                    <v-icon size="14" :color="$store.state.budget.status[1].color">{{
                    $store.state.budget.status[1].icon
                  }}</v-icon>
                  <span><strong>Gesamt</strong></span>
                </td>
                 <td 
                        class="text-right"
                        v-for="(col, index) in columns" 
                        :key="index"
                        v-html="totals['status_12'][index + 1]"
                    >
                    </td>
                    <td class="text-right"
                    v-html="totals['status_12'].at(-1)"
                    ></td><td class="text-right"></td>
              </tr>


              <tr>
                <td>
                  <v-icon size="14" :color="$store.state.budget.status[0].color">{{
                $store.state.budget.status[0].icon
              }}</v-icon>
                <span>{{ $store.state.budget.status[0].label }}</span>
                </td>
                <td 
                        class="text-right"
                        v-for="(col, index) in columns" 
                        :key="index"
                        v-html="totals['status_' + $store.state.budget.status[0].value][index+1]"
                    >
                    </td>
                    <td class="text-right"
                    v-html="totals['status_' + $store.state.budget.status[0].value].at(-1)"
                    ></td><td class="text-right"></td>
              </tr>
              <tr class="totals">
                <td>
                   <v-icon size="14" :color="$store.state.budget.status[2].color">{{
                    $store.state.budget.status[2].icon
                  }}</v-icon>
                    <v-icon size="14" :color="$store.state.budget.status[1].color">{{
                    $store.state.budget.status[1].icon
                  }}</v-icon>
                    <v-icon size="14" :color="$store.state.budget.status[0].color">{{
                    $store.state.budget.status[0].icon
                  }}</v-icon>
                  <span><strong>Gesamt</strong></span>
                </td>
                 <td 
                        class="text-right"
                        v-for="(col, index) in columns" 
                        :key="index"
                        v-html="totals['status_012'][index+1]"
                    >
                    </td>
                    <td class="text-right"
                    v-html="totals['status_012'].at(-1)"></td><td class="text-right"></td>
              </tr>

            </tbody>
      </v-simple-table>
    </v-row>
    
    <v-row>
        <v-simple-table dense class="elevation-2 mb-6" style="width:100%">
            <thead>
                <tr>
                    <th class="primary white--text text-left">{{ keys.find(k => k.value === key).label }}</th>
                    <th class="primary white--text text-right" v-for="col in columns" :key="col">{{ col }}</th>
                    <th class="primary white--text text-right">Diff.*</th>
                    <th class="primary white--text text-right">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in tableData" :key="row[0].id">
                    <td v-html="row[0].label"></td>
                    <td 
                        class="text-right"
                        v-for="(col, index) in columns" 
                        :key="index"
                        v-html="row[index+1]"
                    >
                    </td>
                   <td 
                        class="text-right"
                        v-html="row.at(-1)"
                    ></td>
                    <td>
                        <Changelog
                            :by="key"
                            :value="row[0].id"
                            :status="status"
                            :field="field"
                            :fcid="forecast.id"
                            :useClientGroups="true"
                        > <v-icon small>mdi-timeline-text-outline</v-icon></Changelog>

                        <v-btn
                            v-if="key === 'division_id'"
                            icon
                            plain
                            small
                            class="ml-2"
                            :to="{
                                name: 'BudgetDivision',
                                params: { tenant: current.path, id: row[0].id },
                            }"
                        >
                            <v-icon small>mdi-arrow-right-circle</v-icon>
                        </v-btn>

                    </td>
                </tr>
                <tr v-if="key === 'division_id' && divisions.length > 1"><td :colspan="(columns.length + 3)"></td></tr>
                <tr v-if="key === 'division_id' && divisions.length > 1">
                  <td>
                    <span v-for="s in status" :key="s">
                <v-icon size="16" :color="$store.state.budget.status[s].color">{{
                  $store.state.budget.status[s].icon
                }}</v-icon>
              </span>
              &nbsp;
                    <strong>Budget-Transfers</strong>
                    </td>
                  <td 
                        class="text-right"
                        v-for="(col, index) in columns" 
                        :key="index"
                    >
                    <Transfers v-if="transfers.bySnapshot[col]" :transfers="transfers.bySnapshot[col]" :field="field" :snapshot="col">
                    <span>
                      <v-icon  dense ripple="false">mdi-transfer-right</v-icon>
                      <span> {{ toLocale(transfers.bySnapshot[col].reduce((accumulator, current) => accumulator + current.sum, 0)) }}</span>
                    </span>
                  </Transfers>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </v-simple-table>

    </v-row>
    <v-row>
        <legend class="ml-6 mb-12 small text-start">
          <div>
            <span class="symbol">*</span>
            <span v-if="forecast.snapshots.filter(s => s.closed_at).length < 2">Kein Delta verfügbar, solange nicht mindestens zwei geschlossene Snapshots innerhalb dieses Forecasts vorliegen. </span>
            <span v-else>Differenz zwischen <strong>{{ columns[0] || 'dem ersten'}}</strong> und <strong>{{ columns.at(with_live ? -2 : -1) || 'dem letzten Snapshot' }}</strong>. </span>
            <span v-if="forecast.active">Weitere Veränderungen aus der Spalte "live" sind <u>nicht</u> berücksichtigt.</span></div>
          <div
           v-if="
            key === 'division_id' &&
            divisions.filter((d) => d.deleted_at).length > 0
          "><span class="symbol">**</span><span>Unit führt kein Budget mehr und wurde gelöscht.</span></div>
         
        </legend>
    </v-row>
    </div>
  </v-container>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import Changelog from "@/components/Changelog";
import Transfers from "@/components/modals/Transfers.vue";
import ForecastTools from "@/components/ForecastTools.vue";
import StatusSelector from "@/components/StatusSelector";

import {
  trl,
  getDivisionName,
  getClientName,
  getAttributeName,
} from "@/utils/strings";
import { toLocale, coloredToLocale } from "@/utils/numbers";
import { getClientGroup } from "@/utils/clients";

export default {
  name: "Progress",
  data() {
    return {
      loading: true,
      search: "",
      output: null,
      forecast: null,
      status: [1,2],
      with_live: true,
      maxDate: new Date(),
      field: "total_wo_fk",
      displayMode: 0, // 0 => Sigma, 1 => Delta, 2 => Percent
      suffix: "",
      key: "division_id",
      keys: [
        { value: "division_id", label: trl("budget_numDivisions") },
        { value: "client_id", label: trl("budget_numClients") },
        { value: "attribute_id", label: trl("budget_numAttributes") },
      ],
      data: [],
      transfers: {
        all:[],
        bySnapshot:{}
      },
      columns: [],
      rows: {
        division_id: [],
        client_id: [],
        attribute_id: [],
      },
      totals: {
        status_0: [],
        status_1: [],
        status_2: [],
        status_12: [],
        status_012: []
      },
      headers:[]
    };
  },
  computed: {
    ...mapGetters(["current", "forecasts", "divisions"]),
    tableData(){

        return this.output
            .filter(f => f[0].label.toLowerCase().indexOf((this.search || "").toLowerCase()) > -1)
            .sort((a, b) => a[0].label.localeCompare(b[0].label));
    }
  },
  methods: {
    trl,
    toLocale,
    async init(fcid) {
      this.loading = true;

      this.forecast = this.forecasts.find((f) => f.id == fcid);
      this.with_live = this.forecast.active ? true : false;

      const params = {
        tenant: this.current.id,
        fcid: fcid,
        with_live: this.with_live,
      };

      const data = await this.$store.dispatch("req", {
        route:
          "progress?" +
          Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&"),
      });

      // rewrite all clients to their respective client group
      data.forEach(row => {
        //row.original_client_id = {...row.client_id}
        row.client_id = getClientGroup(row.client_id)
      })

      this.data = data;

      if (!this.with_live){
        this.maxDate = new Date(this.data.map((row) => row.created_at).sort((a,b) => {
            return new Date(a) == new Date(b) ? 0 : new Date(b) > new Date(a) ? -1 : 1;
        }).pop());
    }

     
      // let maxDate = 0;
      // dates.forEach(d => maxDate = Math.max(maxDate, d));
      // console.log(maxDate);

      (this.columns = [...new Set(data.map((row) => row.snapshot))]),
        (this.rows = {
          division_id: [...new Set(data.map((row) => row.division_id))],
          client_id: [...new Set(data.map((row) => row.client_id))],
          attribute_id: [...new Set(data.map((row) => row.attribute_id))],
        });

        this.headers= [{
            text: "",
            value: "label",
            class: "primary white--text",
            value: 0
        }];

        this.columns.forEach((col, index) => {
            this.headers.push({
                text: col,
                value: col,
                class: "primary white--text",
                align: "right"
            })
        })

        this.headers.push({
                text: "Diff*",
                value: "delta",
                class: "primary white--text",
                align: "right"
        })

        this.headers.push({
                text: "tools",
                value: (this.columns.length + 2),
                class: "primary white--text",
                align: "right"
        })


      const transfers = await this.$store.dispatch("req", {
        route:
          "transfers?" +
          Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&"),
      });
       
      this.transfers.all = transfers;
      
      this.loading = false;
      this.setOutput();     
      this.$nextTick(() => {
        let numCols = document.querySelector('table > thead > tr').querySelectorAll('th').length;
        numCols-= 2;

        if (numCols < 10)
        document.querySelectorAll('table > thead > tr > th:nth-child(' + numCols +')').forEach(th => th.style.width = ((12 - numCols) * 100) + "px");
      })
    },
    getRelevantSet() {
      return this.data.map((row) => {
        return {
          snapshot: row.snapshot,
          status: row.status,
          [this.key]: row[this.key],
          [this.field]: row[this.field],
        };
      });
    },
    getTransfers(){
       
        let transfers = {};
        this.columns.forEach(col => {
          let rows = this.transfers.all.filter(row => row.snapshot === col)
          
          if (rows.length > 0) {
            rows.forEach(row => {
              let sum = row.budget.filter(b => this.status.includes(b.status)).reduce((acc, cur) => acc + cur[this.field], 0);
              if (sum === 0) return;
              if (!transfers[col]) transfers[col] = [];
              row.sum = sum;
              transfers[col].push(row);
            })
          }
        })

        this.transfers.bySnapshot = transfers;
    },
    changeKey(){
        this.search = "";
        this.headers[0].text = this.keys.find(k => k.value === this.key).label;
        this.setOutput();
    },
    statuschange(newStatus){
      this.status = newStatus;
      this.setOutput();
    },
    setOutput() {
      let tableData = [];
      let deltaMax = this.with_live ? -2 : -1;

      // gesamte Werte
      let relevantData = this.getRelevantSet();

      this.$store.state.budget.status.forEach(s => {
        let tr = [];
        this.columns.forEach((col) => {
            let sum = relevantData.filter(x => x.snapshot === col && x.status === s.value).reduce((acc, cur) => acc + cur[this.field], 0);
            tr.push(sum);
        })

        let delta;

        switch (this.displayMode) {
            case 0:
                delta = tr.at(deltaMax) - tr[0];
                break;
            case 1:
                delta = tr.at(deltaMax) - tr[0];
                break;
            case 2:
                delta = (tr.at(deltaMax) / tr[0] -1) * 100;
                break;
        }

        tr.push(delta);
        this.totals["status_" + s.value] = tr;
      })

      this.totals.status_12 = [];
      this.totals.status_012 = [];

      this.columns.forEach((col, index) => {
        this.totals.status_12.push(this.totals["status_1"][index] + this.totals["status_2"][index]);
        this.totals.status_012.push(this.totals["status_0"][index] + this.totals["status_1"][index] + this.totals["status_2"][index]);
      })

      switch (this.displayMode) {
            case 0:
              this.totals.status_12 = this.totals.status_12.map(x => toLocale(x));
              this.totals.status_012 = this.totals.status_012.map(x => toLocale(x));
              break;
            case 1:
              this.totals.status_12 = this.totals.status_12.map((td, index) => {
                    return  index > 0 ? coloredToLocale(td - this.totals.status_12[index-1]) : toLocale(td);
                })
              this.totals.status_012 = this.totals.status_012.map((td, index) => {
                    return  index > 0 ? coloredToLocale(td - this.totals.status_012[index-1]) : toLocale(td);
                })
              break;
            case 2:
              this.totals.status_12 = this.totals.status_12.map((td, index) => {
                    return  index > 0 ? coloredToLocale((td / this.totals.status_12[index-1] - 1) * 100, this.suffix) : toLocale(td);
                })
                this.totals.status_012 = this.totals.status_012.map((td, index) => {
                    return  index > 0 ? coloredToLocale((td / this.totals.status_012[index-1] - 1) * 100, this.suffix) : toLocale(td);
                })
              break;
          }


      let deltas = {
        s0: this.totals["status_0"].at(-1),
        s1: this.totals["status_1"].at(-1),
        s2: this.totals["status_2"].at(-1)
      }

      this.totals.status_12.push(coloredToLocale(deltas.s1 + deltas.s2));
      this.totals.status_012.push(coloredToLocale(deltas.s0 + deltas.s1 + deltas.s2));     

      this.totals.status_12.unshift("Gesamt [1,2]");
      this.totals.status_012.unshift("Gesamt [0,1,2]");
      
      this.$store.state.budget.status.forEach(s => {
          switch (this.displayMode) {
            case 0:
              this.totals["status_" + s.value] = this.totals["status_" + s.value].map(x => toLocale(x));
              break;
            case 1: 
              this.totals["status_" + s.value] = this.totals["status_" + s.value].map((td, index) => {
                    return  index > 0 ? coloredToLocale(td - this.totals["status_" + s.value][index-1]) : toLocale(td);
                })
            break;
            case 2:
               this.totals["status_" + s.value] = this.totals["status_" + s.value].map((td, index) => {
                    return  index > 0 ? coloredToLocale((td / this.totals["status_" + s.value][index-1] - 1) * 100, this.suffix) : toLocale(td);
                })
              break;
          }  
          
          this.totals["status_" + s.value].unshift(s.label);
          this.totals["status_" + s.value].push(coloredToLocale(deltas['s' + s.value]));
      })


      // kumulierte Werte

      let base = relevantData.filter((row) =>
        this.status.includes(row.status)
      );

      this.rows[this.key].forEach((id) => {
        let tr = [];
        this.columns.forEach((col) => {
          let filtered = base.filter((x) => x.snapshot === col && x[this.key] === id)

          if (filtered.length === 0) {
            tr.push(null);
          } else {
            let sum = filtered
              .reduce((accumulator, current) => accumulator + current[this.field], 0);

              tr.push(sum);
          }
        });

       

        // Zeile nicht inkludieren, wenn alle Werte 0 sind
        if (this.key == 'client_id' && tr.reduce((acc, cur) => acc + cur, 0) === 0) return;

        let delta;
       
        switch (this.displayMode) {
            case 0:
                delta = tr.at(deltaMax) - tr[0];
                tr = tr.map((td) => {
                  return td ? toLocale(td) : "&mdash;"
                });
                break;
            case 1:
                delta = tr.at(deltaMax) - tr[0];
                tr = tr.map((td, index) => {
                    if (!td) return "&mdash;"
                    return  index > 0 ? coloredToLocale(td - tr[index-1]) : toLocale(td);
                })
                break;
            case 2:
                delta = (tr.at(deltaMax) / tr[0] -1) * 100;
                tr = tr.map((td, index) => {
                    if (!td) return "&mdash;"
                    return  (index === 0 || !tr[index-1] || tr[index-1] == 0) ? toLocale(td) : coloredToLocale((td / tr[index-1] - 1) * 100, this.suffix);
                })
                break;
        }

        tr.push(coloredToLocale(delta, this.suffix));

        let label;
        switch (this.key) {
          case "division_id":
            label = getDivisionName(id);
            let del = this.divisions.find(d => d.id === id).deleted_at
            if (del && new Date(del) < this.maxDate ) label+="&thinsp;**"
            break;
          case "client_id":
            label = getClientName(id);
            break;
          case "attribute_id":
            label = getAttributeName(id);
            break;
        }    

        tr.unshift({id: id, label: label});
        tableData.push(tr);
      });

      this.output = tableData;
      this.getTransfers();

    },
    download() {
      this.$store.commit("toggleSnackbar", {
        show: true,
        text: "Der Download startet in Kürze...",
        timeout: 2000,
      });
     
      this.$store.dispatch("dwnld", {
        download: "progress",
        filename:
          "Entwicklung " +
          this.current.name +
          " " +
          this.forecasts.find((f) => f.id == this.$store.state.activeForecastId).title,
        tenant: this.current.id,
        fcid: this.forecast.id,
        status: this.status,
        field: this.field,
        with_live: false
      });
    }
    
  },
  components: { Changelog, Transfers, ForecastTools, StatusSelector },
  created() {

    if (this.divisions.length === 1) {
      this.key = "client_id";
    }

    this.init(this.$store.state.activeForecastId);
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters.current.permissions.FC_TENANT_READ) {
      next();
    } else {
      next({ name: "NotFound" });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.loading = true;
    //this.init(to.params.id);
    next();
  },
  watch: {
    displayMode: function (val) {
        this.suffix = val === 2 ? '%' : null;
        this.setOutput();
    }
}
};
</script>

<style lang="scss" scoped>

::v-deep table > thead > tr > th{
    width: 100px;
    white-space: nowrap;
}

::v-deep table > thead > tr > th:first-child{
    width: 320px !important;
    white-space: nowrap;

}

::v-deep table > thead > tr > th:last-child{
    width: 90px;
    white-space: nowrap;
}

::v-deep table > tbody td{
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #E0E0E0;
}

::v-deep .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn{
  border-color:#9E9E9E !important;
}

::v-deep .theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon{
  color: rgba(255,255,255,87);
}



::v-deep td.zero{
  opacity:.2;
}



.totals{
  background-color: #f0f0f0;
}

</style>